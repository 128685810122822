import React, {useRef} from 'react';
import {Button, Stack} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import HeroSlide from "../../HeroSlide";
import {Swiper, SwiperSlide} from "swiper/react";
import LargeArrowImage from "../../../assets/LargeArrowImage";
import SwiperCore, {Navigation, Pagination} from "swiper";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme/theme";

const styles = {
    slide: {
        background: "transparent",
        alignItems: 'center',
        maxWidth: "77.44rem",
    },
    button: {
        animationDuration: "1s",
        animationTimingFunction: "ease-in-out",
        animationIterationCount: 1,
        display: {xs: "none", md: "block"},
        backgroundColor: "#947EC7",
        borderRadius: "50%",
        minWidth: "6.2rem",
        width: "6.2rem",
        minHeight: "6.2rem",
        height: "6.2rem",
        top: "calc(50% - 3.1rem)",
        zIndex: 2,
        position: "absolute",
        '&:hover': {
            animationName: "bounce",
            backgroundColor: '#947EC7',
        },
    }
};

function KoThinkerHero() {

    const swiperRef = useRef<SwiperCore>();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    function slide(direction: number) {
        if (direction > 0) {
            swiperRef.current?.slideNext()
        } else {
            swiperRef.current?.slidePrev()
        }

    }

    function breakPointChange(s: SwiperCore) {
        console.log("breakPointChange", s.currentBreakpoint)
        if (s.pagination.el) {
            if (s.currentBreakpoint === 'max') {
                s.pagination.el.classList.add("hero-pagination")
                s.pagination.el.classList.remove("hero-pagination-hidden")
            } else {
                s.pagination.el.classList.remove("hero-pagination")
                s.pagination.el.classList.add("hero-pagination-hidden")
            }
        }

    }


    return (


        <Stack sx={{
            maxWidth: {xs: "calc(100% - 2rem)", md: "calc(100% - 0rem)"},
            width: {xs: "calc(100% - 2rem)", md: "calc(100% - 0rem)"},
            paddingLeft: {xs: "1rem", md: "4rem"},
            paddingRight: {xs: "1rem", md: "4rem"},
            position: "relative",
        }}>
            <Button sx={{
                ...styles.button,
                left: "calc(50% + min(77.44rem, (100vw - 6.2rem)) / 2 - 3.1rem)",
            }} variant={"outlined"}
                    onClick={() => slide(1)}
            >
                <LargeArrowImage width={3.1}/>
            </Button>
            <Button
                sx={{
                    ...styles.button,
                    left: "calc(50% - min(77.44rem, (100vw - 6.2rem)) / 2 - 3.1rem)",
                }}
                variant={"outlined"}
                onClick={() => slide(-1)}
            >
                <LargeArrowImage width={3.1} reverse={true}/>
            </Button>

            <Swiper modules={[Navigation, Pagination]}
                    grabCursor={true}
                    className="hero"
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    spaceBetween={8}
                    onInit={(s) => {
                        //breakPointChange(s)
                    }}
                    loopedSlides={3}
                    loop={true}
                    initialSlide={0}
                    centeredSlides={true}
                    slidesPerView={1.1}
                    style={{
                        position: "relative",
                        zIndex: 1,
                        width: "100%",
                        alignItems: 'center',
                    }}
                    breakpoints={{
                        // when window width is >= 900px
                        900: {
                            spaceBetween: 0,
                            slidesPerView: "auto"
                        }
                    }}

            >
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero01.webp"} title={"Mastermind groups for product managers"}

                               description={"A small group of co-thinkers dedicated to saving time and accelerating growth"}
                               background={"linear-gradient(99.49deg, #5637D5 0%, #9D38ED 101.14%);"}/>
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero02.webp"} title={"Onboard for matchmaking"}
                               background={"linear-gradient(266.42deg, #37CBD5 1.9%, #9D38ED 96.48%);"}
                               description={"Skip the randomness"}/>
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero03.webp"} title={"Skill assessment made simple"}
                               description={"Substitute the information overload with a focused growth system"}
                               background={"linear-gradient(88.94deg, #37CBD5 0.91%, #9D38ED 99.3%);"}
                    />
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero04.webp"} title={"Guided and structured online sessions"}
                               description={"Meet your co-thinkers twice a month"}
                               background={"linear-gradient(267.18deg, #5637D5 1.52%, #9D38ED 98.25%);"}
                    />
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero05.webp"} title={"Action-driven progress"}
                               description={"Peer accountability pushes to do on-the-job learning experiments"}
                               background={"linear-gradient(99.49deg, #5637D5 0%, #9D38ED 101.14%);"}
                    />
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero06.webp"} title={"Celebrate growth together"}
                               description={"KoThinkers say: \"Find your tribe, leave loneliness behind\""}
                               background={"linear-gradient(99.49deg, #9D38ED 0%, #5637D5 101.14%);"}
                    />
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <HeroSlide image={"hero07.webp"} title={"KoThinker group member case study"}
                               description={"The story of Mikk's transformation towards a product-operating model"}
                               buttonTitle={"Read more"}
                               buttonLink={"https://kothinker.com/blog/from-technical-tasks-to-strategic-vision-a-case-study-of-a-kothinker-mastermind-group-member-mikk-vachtmeister-"}
                               background={"linear-gradient(99.49deg, #9D38ED 0%, #5637D5 101.14%);"}
                    />
                </SwiperSlide>


            </Swiper>

        </Stack>

    )
        ;
}

export default KoThinkerHero;
