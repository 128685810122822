import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import KoThinkerImage from "../../../assets/KoThinkerImage";
import theme from "../../theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = {
    dot: {
        height: "9.4rem",
        width: "calc(20.8rem - 6.4rem)",
        backgroundColor: "#F6F6F9",
        borderRadius: "11rem",
        position: "absolute",
        zIndex: 3,
        alignItems: "center",
        justifyContent: "center",
        padding: "0 3.2rem 0 3.2rem"
    },
    circle: {
        height: {md: "24.75rem", xs: "15rem"},
        width: {md: "24.75rem", xs: "15rem"},
        border: "1px solid #DED5F3",
        background: "rgba(103, 106, 167, 0.05)",
        borderRadius: "50%",
        position: "absolute",
        zIndex: 3,
        alignItems: "center",
        justifyContent: "center",
    },
    dotText: {
        color: "#000000",
        textAlign: "center",
        fontSize: {md: '1rem', xs: '0.75rem'},
        lineHeight: {md: '1rem', xs: '0.8rem'},
        width: {md: '10rem', xs: '7rem'},
    },
    headingStyle: {
        fontSize: '1.75rem',
        color: '#8E87A1',
        fontFamily: 'Baloo'
    },
    headingStyle2: {
        display: "inline",
        fontSize: '1.75rem',
        color: '#8B6BAE',
        fontFamily: 'Baloo'
    },
    headingStyle3: {
        fontSize: {md: '1.5rem', xs: '0.875rem'},
        fontWeight: 700,
        color: '#8B6BAE',
        fontFamily: 'Baloo 2'
    },
    numberStyle: {
        color: '#8B6BAE',
        fontSize: '2.25rem',
        fontFamily: 'Baloo 2',
        marginBottom: {md: "1rem", xs: "0.2rem"},
        fontWeight: 700
    }
};

function KothinkerStats() {

    const md = useMediaQuery(theme.breakpoints.up('md'));


    return (

        <Stack sx={{
            maxWidth: {md: "56rem", xs: "23rem"},
            width: "100%",
            paddingLeft: {xs: "1rem", md: "4rem"},
            paddingRight: {xs: "1rem", md: "4rem"},
            alignItems: "center",
            paddingTop: "1rem",
            //paddingBottom: "calc(5.87rem + 1.5rem)",
            zIndex: 0,
            position: "relative",
            textAlign: "center"
        }}>

            <Typography sx={styles.headingStyle}>
                Too many professionals feel <Typography sx={styles.headingStyle2}>alone with their
                challenges.</Typography> This results in <Typography sx={styles.headingStyle2}>self-doubt, decreased
                work joy, and a lack of growth</Typography></Typography>

            <Box sx={{
                mt: 6,
                maxWidth: "38rem",
                width: "100%",
                height: {md: "33.5rem", xs: "22rem"},
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            }}>
                <Stack sx={{
                    ...styles.circle,
                    right: 0,
                    zIndex: 3
                }}>
                    <Box sx={{width: {md: '12.3rem', xs: '7rem'}, position: 'absolute', top: '20%', right: '5%'}}>
                        <Typography sx={styles.numberStyle}>12%</Typography>
                        <Typography sx={styles.dotText}>of employees apply new skills learned at
                            their job <Typography component={"span"} sx={{lineHeight: {md: '1rem', xs: '0.8rem'}}}>**</Typography></Typography>
                    </Box>
                </Stack>
                <Stack sx={{
                    ...styles.circle,
                    zIndex: 4,
                    alignItems: "center",
                }}>
                    <Box sx={{width: {md: '12.3rem', xs: '7rem'}, position: 'absolute', top: '20%', left: '5%'}}>
                        <Typography sx={styles.numberStyle}>45%</Typography>
                        <Typography sx={styles.dotText}>of companies with Product Managers lack Product
                            training <Typography component={"span"} sx={{lineHeight: {md: '1rem', xs: '0.8rem'}}}>*</Typography></Typography>
                    </Box>
                </Stack>
                <Stack sx={{
                    ...styles.circle,
                    bottom: 0,
                    left: {md: "calc(50% - 12.375rem)", xs: "calc(50% - 7.5rem)"},
                    zIndex: 5
                }}>
                    <Box sx={{width: {md: '12.3rem', xs: '7rem'}, position: 'absolute', bottom: {md: '5%', xs:'20%'}}}>
                        <Typography sx={styles.numberStyle}>74%</Typography>
                        <Typography sx={styles.dotText}>of employees feel they aren't reaching
                            their full potential <Typography component={"span"} sx={{lineHeight: {md: '1rem', xs: '0.8rem'}}}>***</Typography></Typography>
                    </Box>
                </Stack>

                <Stack sx={{
                    position: "absolute",
                    top: {md:"calc(50% - 3rem)", xs:"calc(50% - 2rem)"},
                    left: {md: "calc(50% - 10.5rem)", xs: "calc(50% - 6.5rem)"},
                    maxWidth: {md: "21rem", xs: "13rem"},
                    width: {md: "21rem", xs: "13rem"},
                    alignItems: "center",
                    textAlign: "center",
                    zIndex: 6,
                }}>
                    <Box sx={{textAlign: "center"}}>
                        <KoThinkerImage width={md ? 7.7 : 4}/>
                        <Typography sx={styles.headingStyle3}>Supportive co-thinkers <Typography
                            sx={{display: "inline", fontSize: "1.5rem", color: '#404040'}}>help to create a</Typography>
                            <Typography sx={{display: "inline", fontSize: "1.5rem", color: '#8B6BAE',}}> meaningful
                                impact.</Typography></Typography>
                    </Box>

                </Stack>

            </Box>
            <Box sx={{marginTop: "3rem", fontSize:"0.8rem"}}>
                * <a href="https://learning.linkedin.com/content/dam/me/learning/en-us/pdfs/workplace-learning-report/LinkedIn-Learning_Workplace-Learning-Report-2023-EN.pdf" target="_blank">LinkedIn Learning, Workplace Learning Report</a><br/>
                ** <a href="https://www.shiftelearning.com/blog/statistics-on-corporate-training-and-what-they-mean-for-your-companys-future" target="_blank">A report by 24x7 Learning: "Workplace Learning - 2015". September 2015.</a><br/>
                *** <a href="https://www.mdx.ac.uk/courses/professional-practice-and-work-based-learning" target="_blank">Middlesex University London, Professional practice and work based learning</a>
            </Box>

            {
                /*
                <Stack sx={{
                position: "absolute",
                top: "4.8rem",
                left: "calc(50% - 10.4rem + 2.4rem)",
                maxWidth: "40rem",
                width: "40rem",
                zIndex: 2,
                backgroundColor: "#FFFFFF"
            }}>
                <Typography variant={"h1"}
                            sx={{
                                whiteSpace: "pre-line", fontSize: '3rem', lineHeight: "3rem"
                            }}>{"\n\n\nWell-structured mastermind groups lead to mastery\n\n"}</Typography>
            </Stack>
                 */
            }


            {
                /*
                <Box sx={{
                border: "#947EC7 solid 2.8rem",
                maxWidth: "20.8rem",
                width: "50%",
                height: "20.8rem",
                position: "relative",
            }}>
                <Stack sx={{
                    ...styles.dot,
                    top: "-6rem",
                    left: "50%"
                }}>
                    <Typography sx={{marginBottom: "1rem"}} variant={"h4"}>12%</Typography>
                    <Typography sx={styles.dotText}>of employees apply new skills learned at
                        their job</Typography>
                </Stack>
                <Stack sx={{
                    ...styles.dot,
                    top: "calc(50% - 5.87rem)",
                    right: "105%"
                }}>
                    <Typography sx={{marginBottom: "1rem"}} variant={"h4"}>45%</Typography>
                    <Typography sx={styles.dotText}>of companies with Product Managers lack Product training.</Typography>
                </Stack>
                <Stack sx={{
                    ...styles.dot,
                    top: "calc(100% + 1.5rem)",
                    left: "80%"
                }}>
                    <Typography sx={{marginBottom: "1rem"}} variant={"h4"}>74%</Typography>
                    <Typography sx={styles.dotText}>of employees feel they aren't reaching
                        their full potential</Typography>
                </Stack>

            </Box>
            <Stack sx={{
                position: "absolute",
                top: "4.8rem",
                left: "calc(50% - 10.4rem + 2.4rem)",
                maxWidth: "40rem",
                width: "40rem",
                zIndex: 2,
                backgroundColor: "#FFFFFF"
            }}>
                <Typography variant={"h1"}
                            sx={{
                                whiteSpace: "pre-line", fontSize: '3rem', lineHeight: "3rem"
                            }}>{"\n\n\nWell-structured mastermind groups lead to mastery\n\n"}</Typography>
            </Stack>
                 */
            }


        </Stack>
    )
        ;
}

export default KothinkerStats;
