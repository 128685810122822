import React from 'react';
import {
    Button,
    Grid,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import CtaButton from "../../../CtaButton";
import {Icon} from "@iconify/react";
import ArrowImage from "../../../assets/ArrowImage";

const rows = [
    createData('Group size', '10-50', 'Larger groups limit depth', '5-20', 'Small to medium groups for focused instruction', '5-6', 'Small, intimate groups for personal connection'),
    createData('Group match', 'Random', 'Based on interest in topic', 'Somewhat', 'Based on learning objectives', 'High importance', 'Carefully selected to enhance group dynamics'),
    createData('Conversation depth', 'General', 'Surface-level discussions', 'Detailed', 'Topic-specific with practical focus', 'Intensive', 'Deep dives into personal and professional situations'),
    createData('Personalization', 'Low', 'General topics of interest', 'Medium', 'Targeted skills or knowledge', 'High', 'Individual goals and challenges'),
    createData('Structure', 'Rarely', 'More community sharing', 'Yes', 'Designed to teach specific skills', 'Very', 'Personalized engagement and strategic guidance'),
    createData('Feedback loop', 'None', 'Informal knowledge sharing', 'None', 'Focus on course content delivery', 'Constant', 'Continuous interaction and adaptation'),
    createData('Learning approach', 'Informal', 'Knowledge sharing', 'Formal', 'Curriculum-based instruction', 'Collaborative', 'Focus on mutual growth'),
];

function createData(
    element: string,
    meetups: string,
    meetupsSmall: string,
    trainings: string,
    trainingsSmall: string,
    kothinker: string,
    kothinkerSmall: string
) {
    return {element, meetups, meetupsSmall, trainings, trainingsSmall, kothinker, kothinkerSmall};
}

const styles = {
    header: {
        color: "#8938E6",
        border: "none",
        fontWeight: "700",
        fontSize: "1.125rem",
    },
    subheader: {
        fontWeight: "700",
        color: "#323348",
        fontSize: "1.125rem",
    },
    text: {
        fontSize: "1.125rem",
        lineHeight: "1.125rem",
        height: "2rem",
        color: "#6B6C89",
    }
};


function KotThinkerHow() {

    return (

        <Grid container sx={{
            maxWidth: "85.44rem",
            width: "100%",
            paddingLeft: {xs: "1rem", md: "4rem"},
            paddingRight: {xs: "1rem", md: "4rem"},
        }} alignItems={"stretch"}>
            <Grid item xs={12} md={6} sx={{display: "flex", flexDirection: "column"}}>

                <Stack sx={{
                    justifyContent: "center",
                    alignItems: "left",
                    backgroundColor: "#F6F6F9",
                    borderRadius: {md: "13.75rem", xs: "6.75rem"},
                    paddingTop: {md: "4rem", xs: "3rem"},
                    paddingBottom: {md: "4rem", xs: "3rem"},
                    paddingLeft: {md: "20%", xs: "15%"},
                    paddingRight: {md: "10%", xs: "10%", lg: "20%"},
                    marginBottom: {md: "2rem", xs: "1rem"},
                    marginRight: {md: "1rem", xs: "0rem"}
                }}>

                    <Typography variant={"h1"} sx={{mb: "1rem"}}>Who</Typography>
                    <Typography
                        sx={{marginBottom: "0.5rem"}}>
                        Role: <strong>A product person</strong>
                        <Tooltip
                            title={'Product managers, Product leads, Product ops, Product owners, CPOs and Founders'}
                            placement={"right"}>
                            <Icon icon={"mdi:information-outline"} color={"#947EC7"} width={"1rem"}
                                  style={{marginLeft: "0.2rem"}}/>
                        </Tooltip>
                    </Typography>
                    <Typography>
                        You have:<br/>
                    </Typography>
                    <ul style={{marginTop: 0}}>
                        <li><Typography>Experience to share</Typography></li>
                        <li><Typography>Curiosity to see how others solve the same challenges</Typography></li>
                        <li><Typography>Habit of continuous learning</Typography></li>
                        <li><Typography>Courage to share your opinion</Typography></li>
                        <li><Typography>Drive to initiate changes</Typography></li>
                    </ul>
                    <Typography>
                        Test your knowledge by taking the quiz below.
                    </Typography>
                    <Link target={"_blank"}
                          href={"https://docs.google.com/forms/d/e/1FAIpQLSdGzvgUl8aS4a2gPo733MISFpZraaqDNM6NNCf6fvRo5hvWYQ/viewform"}>
                        <Button sx={{marginTop: {md: "2rem", xs: "0rem"}}} variant={"contained"}>
                            Take the quiz<ArrowImage width={1.25}/>
                        </Button>
                    </Link>
                </Stack>
                <Stack sx={{
                    justifyContent: "center",
                    alignItems: "left",
                    backgroundColor: "#F6F6F9",
                    borderRadius: {md: "13.75rem", xs: "6.75rem"},
                    paddingTop: {md: "4rem", xs: "3rem"},
                    paddingBottom: {md: "4rem", xs: "3rem"},
                    paddingLeft: {md: "20%", xs: "15%"},
                    paddingRight: {md: "10%", xs: "10%", lg: "20%"},
                    marginRight: {md: "1rem", xs: "0rem"},
                    marginBottom: {md: "0rem", xs: "1rem"}
                }}>
                    <Typography variant={"h1"} sx={{mb: "1rem"}}>How</Typography>
                    <Typography
                        sx={{marginBottom: "0.5rem"}}>
                    </Typography>
                    <Typography sx={{marginBottom: "0.5rem"}}>
                        1. Create KoThinker account
                    </Typography>
                    <Typography sx={{marginBottom: "0.5rem"}}>
                        2. Assess your current <Typography
                        component={"span"} sx={{
                        color: "#000000",
                        fontWeight: "600"
                    }}>knowledge gaps</Typography>
                    </Typography>
                    <Typography sx={{marginBottom: "0.5rem"}}>
                        3. Find the time and group match
                    </Typography>
                    <Typography sx={{marginBottom: "0.5rem"}}>
                        4. Join <Typography
                        component={"span"} sx={{
                        color: "#000000",
                        fontWeight: "600"
                    }}>online sessions</Typography> twice a month
                    </Typography>
                    <Typography sx={{marginBottom: "0.5rem"}}>
                        5. Track your progress
                    </Typography>
                    <Typography>
                        6. Create a meaningful impact with your product
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{minHeight: "100%"}}>

                <Stack sx={{
                    height: "calc(100% - 8rem)",
                    justifyContent: "center",
                    alignItems: "left",
                    backgroundColor: "#F6F6F9",
                    borderRadius: {md: "13.75rem", xs: "6.75rem"},
                    paddingLeft: {md: "20%", xs: "15%"},
                    paddingRight: {md: "10%", xs: "10%", lg: "20%"},
                    marginBottom: "2rem",
                    marginLeft: {md: "1rem", xs: "0rem"},
                    paddingTop: {md: "4rem", xs: "3rem"},
                    paddingBottom: {md: "4rem", xs: "3rem"},
                }}>
                    <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"} marginBottom={"2rem"}>
                        <img src={"/images/mental1.webp"} alt={"Mental fog"} style={{width: "50%", height: "auto"}}/>
                        <img src={"/images/mental2.webp"} alt={"Clarity"} style={{width: "50%", height: "auto"}}/>
                    </Stack>
                    <Typography variant={"h1"} sx={{mb: "2rem"}}>Why</Typography>
                    <Typography
                        sx={{marginBottom: "0.5rem"}}>
                        <Typography component={"span"} sx={{
                            color: "#000000",
                            fontWeight: "600"
                        }}>Share your passion</Typography> with other growth-minded experts.
                    </Typography>
                    <Typography
                        sx={{marginBottom: "0.5rem"}}>
                        <Typography component={"span"} sx={{
                            color: "#000000",
                            fontWeight: "600"
                        }}>Gain situational feedback.</Typography> A mind that feeds only on itself is soon
                        undernourished.
                    </Typography>
                    <Typography
                        sx={{}}>
                        <Typography component={"span"} sx={{
                            color: "#000000",
                            fontWeight: "600"
                        }}>Upskill</Typography> through:
                    </Typography>
                    <ul style={{marginTop: "0.5rem"}}>
                        <li>
                            <Typography sx={{}}>
                                Collaborative learning
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{}}>
                                Sharing experiences
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{}}>
                                Building a habit of practice
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{}}>
                                Systemizing your growth
                            </Typography>
                        </li>
                    </ul>
                    <Typography
                        sx={{marginBottom: "0.5rem"}}>
                        <Typography component={"span"} sx={{
                            color: "#000000",
                            fontWeight: "600"
                        }}>Increase confidence </Typography> in decision-making and aligning communication between
                        various stakeholders
                    </Typography>
                    <Typography
                        sx={{marginBottom: "0.5rem"}}>
                        Explore product growth beyond your bubble!
                    </Typography>
                    <CtaButton backgroundColor={"#947EC7"} color={"#FFFFFF"}/>

                </Stack>


            </Grid>
            <Grid item xs={12} lg={12}>
                <Typography
                    variant={"h1"}
                    id="eventSection"
                    sx={{
                        textAlign: "center",
                        alignSelf: "center",
                        marginTop: "5rem",
                        fontSize: {xs: "1.5rem", md: "3.75rem"},
                        color: "#947EC7",
                        lineHeight: {xs: "1.7rem", md: "3.75rem"},
                    }}>How do we stand out </Typography>
            </Grid>
            <Grid item xs={12} sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: {md: "3rem", xs: "0rem"}
            }}>
                <TableContainer component={Paper} elevation={0} sx={{display: {xs: "none", md: "block"}}}>
                    <Table sx={{border: "none", boxShadow: "none"}} aria-label="simple table">
                        <TableHead sx={{border: "none"}}>
                            <TableRow sx={{border: "none"}}>
                                <TableCell sx={styles.header}>Comparison Element</TableCell>
                                <TableCell sx={styles.header}>Meetups</TableCell>
                                <TableCell sx={styles.header}>Trainings</TableCell>
                                <TableCell sx={styles.header}>KoThinker Mastermind Groups</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={row.element}
                                >
                                    <TableCell sx={{
                                        bgcolor: index % 2 === 0 ? '#F5F5FD' : 'inherit',
                                        verticalAlign: "top",
                                        border: "none",
                                        borderRadius: "10px 0 0 10px",
                                    }}>
                                        <Typography sx={styles.subheader}>{row.element}</Typography>
                                    </TableCell>
                                    <TableCell sx={{
                                        border: "none",
                                        verticalAlign: "top",
                                        bgcolor: index % 2 === 0 ? '#F5F5FD' : 'inherit',
                                    }}>
                                        <Typography sx={styles.subheader}>{row.meetups}</Typography>
                                        <Typography sx={styles.text}>{row.meetupsSmall}</Typography>
                                    </TableCell>
                                    <TableCell sx={{
                                        border: "none",
                                        verticalAlign: "top",
                                        bgcolor: index % 2 === 0 ? '#F5F5FD' : 'inherit',
                                    }}>
                                        <Typography sx={styles.subheader}>{row.trainings}</Typography>
                                        <Typography sx={styles.text}>{row.trainingsSmall}</Typography>
                                    </TableCell>
                                    <TableCell sx={{
                                        border: "none",
                                        verticalAlign: "top",
                                        borderRadius: "0 10px 10px 0",
                                        bgcolor: index % 2 === 0 ? '#F5F5FD' : 'inherit',
                                    }}>
                                        <Typography sx={styles.subheader}>{row.kothinker}</Typography>
                                        <Typography sx={styles.text}>{row.kothinkerSmall}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>

        </Grid>
    )
        ;
}

export default KotThinkerHow;
