import React, {useEffect} from 'react';
import {Box, Button, Grid, Link, Stack, Typography} from "@mui/material";
import ArrowImage from "../../../assets/ArrowImage";
import CtaButton from "../../../CtaButton";
import {referralAtom} from "../../../store/referralStore";
import {useAtomValue} from 'jotai';
import {Link as NextLink} from "react-router-dom";


const styles = {
    dot: {
        justifyContent: "top",
        alignItems: "center",
        backgroundColor: "#F6F6F9",
        borderRadius: "2rem",
        paddingTop: {md: "2rem", xs: "1rem"},
        paddingBottom: {md: "2rem", xs: "1rem"},
        paddingLeft: {md: "3rem", xs: "1rem"},
        paddingRight: {md: "3rem", xs: "1rem"},
        marginRight: {md: "1rem", xs: 0},
        display: "flex",
        flexDirection: "column",
        height: {md: "calc(100% - 4rem)", xs: "calc(100% - 2rem)"},
    },
    activeCurrency: {
        height: "2rem",
        color: "#947EC7",
        borderBottom: "2px solid #947EC7",

    },
    inActiveCurrency: {
        height: "2rem",
        color: "#000000",
        borderBottom: "none",
    }
};

function KoThinkerPricing() {
    const referralCode = useAtomValue(referralAtom);

    const [currency, setCurrency] = React.useState("EUR");

    useEffect(() => {

        const userLocale = navigator.language
        console.log(userLocale)
        if (userLocale === 'en-GB') {
            setCurrency("GBP");
        } else {
            setCurrency("EUR");
        }
    }, []); // Run this effect only once on component mount

    return (
        <>
            <Stack sx={{
                maxWidth: "77.44rem",
                width: {xs: "calc(100% - 2rem)", md: "calc(100% - 8rem)"},
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <Typography variant={"h1"} sx={{
                        textAlign: "center",
                        fontSize: {xs: "1.5rem", md: "3.75rem"},
                        lineHeight: {xs: "1.7rem", md: "3.75rem"},
                        mb: {xs: "1rem", md: "2rem"},
                    }}>Get started</Typography>
                    <Stack direction={"row"}>
                        <Typography
                            onClick={() => setCurrency("EUR")}
                            sx={{
                                fontWeight: "600",
                                fontSize: "1.5rem",
                                mr: "2rem",
                                cursor: "pointer",
                                ...(currency === "EUR" ? styles.activeCurrency : styles.inActiveCurrency)
                            }}>
                            EUR
                        </Typography>
                        <Typography onClick={() => setCurrency("GBP")} sx={{
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            cursor: "pointer",
                            ...(currency === "GBP" ? styles.activeCurrency : styles.inActiveCurrency)
                        }}>
                            GBP
                        </Typography>

                    </Stack>
                </Box>

                <Grid columnSpacing={0} container alignItems="stretch">

                    <Grid item xs={12} md={6} sx={{
                        justifyContent: "top",
                        alignItems: "center",
                        borderRadius: "2rem",
                        padding: {md: "0rem 0.5rem 0rem 0.5rem", xs: "1rem 1rem 1rem 1rem"},
                    }}>
                        <Stack sx={styles.dot}>
                            <Typography sx={{color: "#947EC7", fontSize: "1.125rem", fontWeight: "700"}}>Group member
                                subscription</Typography>
                            <Typography sx={{mt: "0.5rem", color: "#000000", fontSize: "2.5rem", fontWeight: "700"}}>
                                {currency === "GBP" ? "80 GBP" : "95 EUR"} + vat
                            </Typography>
                            <Typography sx={{
                                mt: "0.5rem",
                                mb: "0.5rem",
                                color: "#434343",
                                fontSize: "1.125rem",
                                fontWeight: "700"
                            }}>per month</Typography>

                            <ul style={{
                                marginTop: "0.5rem",
                                color: "#000000",
                                fontSize: "1rem",
                                lineHeight: "1.875rem",
                                fontWeight: "400",
                                whiteSpace: "pre-line"
                            }}>
                                <li>Access to a private group that matches your profile<br/>...with a friendly peer
                                    pressure to grow
                                </li>
                                <li>18 structured group sessions in nine months</li>
                                <li>Personalized session topic selection</li>
                                <li>Situational feedback to your on-the-job learning experiments</li>
                                <li>Track your progress through the 9-month cycle</li>
                                <li>You can earn <NextLink to={"/virtualshares"}>virtual shares</NextLink> and be a
                                    part of KoThinker's success
                                </li>
                                <p>(One-month money back/new group guarantee if the group is not a match)</p>
                            </ul>

                            <Stack sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end"
                            }}> <CtaButton backgroundColor={"#947EC7"} color={"#FFFFFF"}/>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{
                        justifyContent: "top",
                        alignItems: "center",
                        borderRadius: "2rem",
                        padding: {md: "0rem 0rem 0rem 0.5rem", xs: "1rem 1rem 1rem 1rem"},
                    }}>
                        <Stack sx={styles.dot}>
                            <Typography sx={{color: "#947EC7", fontSize: "1.125rem", fontWeight: "700"}}>Community
                                member</Typography>
                            <Typography sx={{mt: "0.5rem", color: "#000000", fontSize: "2.5rem", fontWeight: "700"}}>
                                FREE
                            </Typography>
                            <Typography sx={{
                                marginTop: "3.35rem",
                                color: "#000000",
                                fontSize: "1rem",
                                lineHeight: "1.5rem",
                                fontWeight: "400",
                            }}>
                                Not completely ready to jump all in yet? No worries, we understand your hesitation. As a
                                start, you can try out for free:
                            </Typography>

                            <ul style={{
                                color: "#000000",
                                fontSize: "1rem",
                                lineHeight: "1.875rem",
                                fontWeight: "400",
                                whiteSpace: "pre-line"
                            }}>
                                <li>Knowledge gap assessment</li>
                                <li>Monthly discussion events with experienced experts</li>
                                <li>Monthly reading club sessions with fellow community members</li>
                                <li>A curated list of inspiring influencers, blogs, and reading suggestions shared by
                                    other co-thinkers
                                </li>
                                <li>Slack community for influencing KoThinker's content and hearing everything
                                    firsthand
                                </li>
                            </ul>

                            <Stack sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end"
                            }}>
                                <Link target={"_blank"}
                                      href={referralCode ? 'https://app.kothinker.com?ref=' + referralCode : 'https://app.kothinker.com'}>
                                    <Button sx={{marginTop: {md: "2rem", xs: "0rem"}}} variant={"contained"}>
                                        Let's go<ArrowImage width={1.25}/>
                                    </Button>
                                </Link>
                            </Stack>
                        </Stack>

                    </Grid>
                </Grid>

                <Stack sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: {lg: "10rem", xs: "2rem"},
                    width: "100%"
                }}>

                    <Stack sx={{
                        maxWidth: "45rem", position: "relative",
                    }}>
                        <Box sx={{
                            display: {xs: "none", md: "none", lg: "block"},
                            position: "absolute",
                            top: "-6rem",
                            right: "-12rem",
                            backgroundImage: "url(/images/think.webp)",
                            width: "11rem",
                            height: "3.75em",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                        />

                        <Box sx={{
                            position: "absolute",
                            display: {xs: "none", md: "none", lg: "block"},
                            top: "-1.5rem",
                            right: "-10rem",
                            backgroundImage: "url(/images/think_arrow.webp)",
                            width: "11rem",
                            height: "3.75em",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                        />
                        <Typography textAlign={"center"} sx={{fontSize: "1.25rem"}}>
                            Compute the cost as a percentage of your gross income and then ask yourself - <Typography
                            component={"span"} sx={{fontWeight: 700, color: "#676AA7", fontSize: "1.25rem"}}>isn’t my
                            future
                            worth this modest investment?</Typography> Why not make an investment decision <Typography
                            component={"span"} sx={{fontWeight: 700, color: "#676AA7", fontSize: "1.25rem"}}>right
                            now?</Typography>It will connect you with people <Typography
                            component={"span"} sx={{fontWeight: 700, color: "#676AA7", fontSize: "1.25rem"}}>who are
                            also headed towards
                            success.</Typography>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>


        </>
    )
        ;
}

export default KoThinkerPricing;
