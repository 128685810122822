import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from "swiper";
import {Grid, Stack, Typography} from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme/theme";
import GroupSlide from "./GroupSlide";
import {IPublicGroupInfo} from "./model/IPublicGroupInfo";
import {GroupStatus} from "./model/IGroupStatus";
import useKoThinkerApi from "../../../api/useKoThinkerApi";
import ArrowImage from "../../../assets/ArrowImage";

const styles = {
    slide: {
        height: "auto",
        width: "auto",
    },
};


function GroupSelection({solutionStoryLayout = false}: { solutionStoryLayout?: boolean }) {

    const [groups, setGroups] = useState<IPublicGroupInfo[]>([])

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const swiperRef = useRef<SwiperCore>();

    const [dynamicOffset, setDynamicOffset] = useState(
        md ? (window.innerWidth / 2 - 619.52) : 14
    );

    const {getGroups} = useKoThinkerApi()


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setDynamicOffset(md ? (window.innerWidth / 2 - 619.52) : 14);
    };

    useEffect(() => {
        getGroups()
            .then((data) => {
                setGroups([...data]);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const swiperButtonStyle: CSSProperties = {
        position: 'relative',
        display: 'inline-flex',
        width: '60px',
        height: '60px',
        marginRight: '16px',
        marginLeft: '16px',
        marginTop: 0,
        borderRadius: '100%',
        backgroundColor: '#947EC7',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    };
    return (

        <>
            <Stack sx={{
                maxWidth: "77.44rem",
                width: {xs: "calc(100% - 2rem)", md: "calc(100% - 8rem)"},
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Grid container>
                    <Grid item xs={0} lg={3}></Grid>
                    <Grid item xs={12} lg={6} px={{xs: 0, md: 3}}>
                        <Typography
                            variant={"h1"}
                            id="groupSelectionSection"
                            sx={{
                                textAlign: "center",
                                alignSelf: solutionStoryLayout ? "start" : "center",
                                marginBottom: solutionStoryLayout ? "1rem" : "1.5rem",
                                marginLeft: solutionStoryLayout ? "1rem" : 0,
                                fontSize: solutionStoryLayout ? {xs: "1.5rem", md: "2.25rem"} : {
                                    xs: "1.5rem",
                                    md: "3.75rem"
                                },
                                color: solutionStoryLayout ? "white" : "#947EC7",
                                lineHeight: {xs: "1.7rem", md: "3.75rem"},
                            }}>Apply for Group</Typography>
                    </Grid>
                    {md && <Grid item xs={12} lg={3} mb={3} alignSelf="start" justifyContent={{xs: "center", md: "end"}}
                                 display="flex">
                        {md && <div
                            className={solutionStoryLayout ? 'swiper-pagination solution-story-pagination' : 'swiper-pagination'}></div>}
                        <div id={"swiper-button-prev-group-selection"}
                             className="swiper-button-prev" style={{...swiperButtonStyle, transform: 'rotate(180deg)'}}>
                            <ArrowImage width={1.4} marginLeft={"0"}/></div>
                        <div id={"swiper-button-next-group-selection"}
                             className="swiper-button-next" style={swiperButtonStyle}><ArrowImage width={1.4}
                                                                                                  marginLeft={"0"}/>
                        </div>
                    </Grid>}
                </Grid>
            </Stack>


            <Grid key={"swiper-container"} container sx={{
                width: "100%",
                position: "relative",
                paddingRight: {xs: "0rem", md: "0rem"},
                paddingLeft: {xs: "1rem", md: "0rem"},
                alignItems: "center",
            }}
                  className={solutionStoryLayout ? "solution-story" : ""}>

                <Swiper modules={[Pagination, Navigation]}
                        className="testimonial"
                        slidesPerView={"auto"}
                        id={"group-selection"}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        slidesOffsetBefore={dynamicOffset}
                        simulateTouch={true}
                        pagination={{
                            clickable: true,
                            horizontalClass: "testimonial-pagination",
                            el: '.swiper-pagination',
                            type: 'fraction',
                        }}
                        navigation={{
                            nextEl: '#swiper-button-next-group-selection',
                            prevEl: '#swiper-button-prev-group-selection',
                        }}
                        loop={false}
                        centeredSlides={false}
                        spaceBetween={8}
                        grabCursor={true}
                        style={{
                            width: "100%",
                            paddingBottom: "2rem",
                        }}
                >
                    {groups.filter(g => g.status === GroupStatus.PENDING).map(group =>
                        <SwiperSlide style={styles.slide} key={group.id}>
                            <GroupSlide groupInfo={group}></GroupSlide>
                        </SwiperSlide>
                    )}

                    <SwiperSlide style={styles.slide} key={"waitinglist"}>
                        <GroupSlide isWaitList={true} groupInfo={{
                            id: 0,
                            languageCode: "en",
                            status: GroupStatus.PENDING,
                            sessionTime: "",
                            name: "Apply for matching",
                            memberCount: 0,
                            maxSize: 0,
                            subName: "You can start here to fill out your details, and let us match you to the right group to bring you the most value",
                            companies: [],
                            moreInfo: "We match groups based on your job, product characteristics, and company stage.\n\n" +
                                "Relax now and wait until we contact you to offer the right group for your needs!\n\n" +
                                "You can see your group members before your final confirmation."
                        }}></GroupSlide>
                    </SwiperSlide>

                    {groups.filter(g => g.status === GroupStatus.ACTIVE || g.status === GroupStatus.WAITING_CONFIRMATIONS).map(group =>
                        <SwiperSlide style={styles.slide} key={group.id}>
                            <GroupSlide groupInfo={group}></GroupSlide>
                        </SwiperSlide>
                    )}
                </Swiper>
                {!md && <Grid item xs={12} mb={3} alignSelf="start" justifyContent={{xs: "center", md: "end"}}
                              display="flex" sx={{marginTop: "-30px"}}>
                    {md && <div
                        className={solutionStoryLayout ? 'swiper-pagination solution-story-pagination' : 'swiper-pagination'}></div>}
                    <div id={"swiper-button-prev-group-selection"}
                         className="swiper-button-prev" style={{...swiperButtonStyle, transform: 'rotate(180deg)'}}>
                        <ArrowImage width={1.4} marginLeft={"0"}/></div>
                    <div id={"swiper-button-next-group-selection"}
                         className="swiper-button-next" style={swiperButtonStyle}><ArrowImage width={1.4}
                                                                                              marginLeft={"0"}/>
                    </div>
                </Grid>}
            </Grid>
        </>
    )
        ;
}

export default GroupSelection;
