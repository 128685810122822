import React, {useState} from 'react';
import "swiper/css";
import "swiper/css/pagination";
import {Box, Button, FormControl, FormLabel, Grid, Stack, TextField, Typography} from "@mui/material";
import ArrowImage from "../../assets/ArrowImage";
import emailjs from '@emailjs/browser';
import {toast} from "react-toastify";
import {isValidEmail} from "../../util/formatUtil";
import Layout from "../Layout";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface FormInfo {
    name: string;
    email: string;
    company: string;
    intention: string;
    info: string;
}

const styles = {
    grid: {
        display: "flex",
        justifyContent: {md: "start", xs: "center"},
        paddingRight: {md: "4rem", xs: "0rem"},
    },
}

const initialForm = {
    name: "",
    email: "",
    company: "",
    intention: "",
    info: "",
} as FormInfo

function Contact() {

    const [form, setForm] = useState<FormInfo>(initialForm)
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setForm({...form, [e.target.id]: e.target.value})
    };

    const handleIntentionChange = (event: SelectChangeEvent) => {
        setForm({...form, intention: event.target.value})
    };

    const isFormValid = () => {
        if (!form) {
            return false;
        }
        const errors = [];

        if (form.name === undefined || form.name?.trim().length === 0) {
            errors.push('name');
        }

        if (!isValidEmail(form?.email!)) {
            errors.push('email');
        }
        setErrorFields(errors);
        return errors.length <= 0;

    };

    const sendForm = () => {
        if (isFormValid()) {

            let params = {
                name: form.name,
                email: form.email,
                intention: form.intention,
                info: form.info
            }

            emailjs.send('kofounder_system', 'kofounder_contact', params, process.env.REACT_APP_EMAIL_JS_KEY)
                .then((result) => {
                    toast.success("Contact request sent. We'll get back to you!")
                    setForm(initialForm)
                }, () => {
                    toast.error("Contact request failed. Please try again or send an e-mail to hello@kothinker.com!")
                });
        }
    };

    return (
        <Layout>
            <>
                <Grid container sx={{
                    maxWidth: "77.44rem",
                    width: "100%"
                }}>
                    <Grid item md={6} xs={12} sx={{padding: {md: "0 2rem 0 0rem", xs:"0 1.5rem 0 1.5rem"}}}>
                        <Box sx={{
                            backgroundImage: "url(/images/contact.webp)",
                            height: {md: "25rem", xs: "13.125rem"},
                            width: "100%",
                            borderRadius: {md: "31.25rem", xs: "13.75rem"},
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}/>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{padding: {md: "0 4rem 0 2rem", xs:"0 2rem 0 2rem"}}}>
                        <Typography variant={"h1"} sx={{
                            marginTop: {md: 0, xs: "2rem"},
                            textAlign: "left",
                            marginBottom: {md: "2rem", xs: "1rem"},
                            fontSize: {xs: "1.5rem", md: "3.75rem"},
                            lineHeight: {xs: "1.7rem", md: "3.75rem"},
                        }}>Contact us</Typography>
                        <Stack>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Name</FormLabel>
                                <TextField value={form.name} id={"name"}
                                           error={errorFields.includes("name")}
                                           helperText={!errorFields.includes("name") ? "" : "Name is required!"}
                                           onChange={(e) => handleChange(e)}/>
                            </FormControl>

                            <FormControl component="fieldset">
                                <FormLabel component="legend">Your email</FormLabel>
                                <TextField value={form.email} id={"email"}
                                           error={errorFields.includes("email")}
                                           helperText={!errorFields.includes("email") ? "" : "Correct e-mail is required!"}
                                           onChange={(e) => handleChange(e)}/>
                            </FormControl>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Additional info</FormLabel>
                                <TextField value={form.info} id={"info"}
                                           minRows={3}
                                           multiline
                                           onChange={(e) => handleChange(e)}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel component="legend">Intention</FormLabel>
                                <Select
                                    value={form.intention}
                                    displayEmpty
                                    onChange={(e) => handleIntentionChange(e)}>
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    <MenuItem value={"kothinkerJoin"}>I wish to join KoThinker group</MenuItem>
                                    <MenuItem value={"kothinkerInvest"}>I wish to invest in KoThinker</MenuItem>
                                    <MenuItem value={"softwareDev"}>I wish to buy software development</MenuItem>
                                    <MenuItem value={"mentor"}>I wish to find a facilitator/mentor</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>

                            </FormControl>
                            <Stack sx={{width: "100%", display: "flex", alignItems: "center"}}>
                                <Button sx={{marginTop: "1.5rem"}} variant={"contained"} onClick={() => sendForm()}>
                                    Send<ArrowImage width={1.25}/>
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>


                <Box sx={{height: {xs: "3rem", md: "8rem"}}}/>
            </>
        </Layout>
    );
}

export default Contact;
