import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "./theme/theme";
import CtaButton from "../CtaButton";

function HeroSlide({
                       image,
                       title,
                       description,
                       background,
                       buttonTitle = "Apply for Group",
                       buttonLink

                   }: {
    image: string,
    title: string,
    description: string,
    background: string,
    buttonTitle?: string,
    buttonLink?: string

}) {

    const md = useMediaQuery(theme.breakpoints.up('md'));


    return (

        <Stack sx={{
            position: "relative",
            background: background,
            borderRadius: {md: "25rem", xs: "5rem"},
            minHeight: {md: "31rem", xs: "25rem"},
            height: {md: "31rem", xs: "35rem"},
            display: "flex",
            width: "100%",
            justifyContent: {xs: "end", md: "center"},
        }}>

            <Grid
                container
                sx={{
                    height: "100%",
                    width: "100%",
                    paddingLeft: {md: "8rem", xs: "2rem"},
                    paddingRight: {md: "8rem", xs: "2rem"},
                    paddingTop: {md: "6rem", xs: "3rem"},
                    paddingBottom: {md: "6rem", xs: "3rem"},
                }}
            >
                <Grid item xs={12} lg={7} md={8} sx={{
                    paddingRight: {md: "2rem", xs: 0},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: {xs: "center", md: "flex-start"},
                    justifyContent: "center",
                }}>
                    <Typography variant={"h1"} sx={{
                        whiteSpace: "pre-line",
                        color: "#FFFFFF",
                        textAlign: "left",
                        fontSize: "3rem",
                        lineHeight: "3rem",
                        width: "100%",
                    }}>
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            whiteSpace: "pre-line",
                            color: "#FFFFFF",
                            textAlign: "left",
                            fontSize: {xs: "1.25rem", md: "1.5rem"},
                            lineHeight: {xs: "1.5rem", md: "2rem"},
                            marginTop: "1rem",
                            maxWidth: {lg: "75%", md: "100%"},
                            width: {xs: "100%", lg: "75%"}
                        }}>
                        {description}
                    </Typography>

                    <Box sx={{display: {xs: "none", md: "block"}}}>
                        <CtaButton title={buttonTitle} link={buttonLink}/>
                    </Box>

                </Grid>
                <Grid item xs={12} md={4} lg={5} sx={{
                    display: "flex",
                    alignItems: {xs: "flex-end", md: "center"},
                    justifyContent: "center",
                }}>
                    <Stack sx={{height: "100%", width: "100%", alignItems: "center", justifyContent: "center"}}>
                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            <CtaButton title={buttonTitle} link={buttonLink}/>
                        </Box>
                        <Box sx={{
                            marginTop: {md: "0", xs: "2rem"},
                            borderRadius: "0.625rem",
                            minWidth: "100%",
                            minHeight: {md: "auto", xs: "9rem"},
                            height: {md: "100%", xs: "9rem"},
                            backgroundImage: "url(/images/" + image + ")",
                            backgroundPosition: {md: "center", xs: "top"},
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>

    )
        ;
}

export default HeroSlide;
