import React from 'react';
import {Button, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {animateScroll as scroll} from "react-scroll";
import theme from "./components/theme/theme";
import ArrowImage from "./assets/ArrowImage";

function CtaButton({backgroundColor = "#01AF82", color = "#FFFFFF", title = "Apply for Group", link}: {
    backgroundColor?: string,
    color?: string,
    title?: string,
    link?: string
}) {

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const handleButtonClick = () => {
        if (link) {
            window.open(link, "_blank");
        } else {
            const groupSelectionSection = document.getElementById("groupSelectionSection");
            if (groupSelectionSection) {
                scroll.scrollTo(groupSelectionSection.offsetTop - 100, {smooth: true, duration: 500});
            }
        }
    };

    return (


        <Button
            variant={"contained"}
            onClick={handleButtonClick}
            sx={{
                mt: "2rem",
                fontSize: {
                    xs: "1.25rem",
                    md: "1.5rem"
                },
                lineHeight: {
                    xs: "1.5rem",
                    md: "2rem"
                },
                backgroundColor: backgroundColor + ' !important',
                border: "1px solid #FFFFFF;"

            }}>
            <Typography sx={{
                marginRight: {md: "1rem", xs: "0.5rem"},
                fontWeight: "600 !important",

                color: color + ' !important',
            }}>
                {title}
            </Typography>

            <ArrowImage width={md ? 1.25 : 1.1} color={color}/>
        </Button>


    );
}

export default CtaButton;
